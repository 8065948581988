<template>
    <div class="GoodsDetail" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <div>
                <el-form ref="form" label-width="80px" width="auto" size="small">
                    <el-form-item label="名称">
                        <el-input readonly v-model="form.sku.name" />
                    </el-form-item>
                    <el-form-item label="部门名称">
                        <el-input readonly v-model="form.deptName" />
                    </el-form-item>
                    <el-form-item label="条码">
                        {{ form.sku.bars.join(' / ') }}
                    </el-form-item>
                    <el-form-item label="类型">
                        <el-input readonly :value="form.sku.type | goodsType" />
                    </el-form-item>
                    <el-form-item label="规格">
                        <el-input readonly v-model="form.sku.specs" />
                    </el-form-item>
                    <el-form-item label="类目">
                        <el-input readonly v-model="form.sku.category" />
                    </el-form-item>
                    <el-form-item label="单位(小)">
                        <el-input readonly v-model="form.sku.unit" />
                    </el-form-item>
                    <el-form-item label="品牌">
                        <el-input readonly v-model="form.sku.brand" />
                    </el-form-item>
                    <el-form-item label="保质期">
                        <el-input readonly v-model="form.sku.expirationDate" />
                    </el-form-item>
                    <el-form-item label="单位(大)" prop="bigUnit" v-if="hasBigUnitPrivilege && !form.sku.type">
                        <el-input readonly v-model="form.bigUnit" />
                    </el-form-item>
                    <el-form-item label="包装因子" prop="packFactor" v-if="hasBigUnitPrivilege && !form.sku.type">
                        <el-input readonly type="number" v-model.number="form.packFactor" />
                    </el-form-item>
                    <el-form-item label="自编码">
                        <el-input readonly v-model="form.customBar" />
                    </el-form-item>
                    <el-form-item label="助记码">
                        <el-input readonly v-model="form.fastBar" />
                    </el-form-item>
                    <el-form-item label="最低库存">
                        <el-input readonly v-model="form.minStock" />
                    </el-form-item>
                    <el-form-item label="最高库存">
                        <el-input readonly v-model="form.maxStock" />
                    </el-form-item>
                    <el-form-item label="状态">
                        <el-input readonly :value="form.status | status" />
                    </el-form-item>
                </el-form>
            </div>
        </el-card>
    </div>
</template>
<script>
import UrlUtils from '../../../../js/UrlUtils';

export default {
    name: 'GoodsDetail',
    props: ['form'],
    data() {
        return {
            meta: {},
            hasBigUnitPrivilege: false,
        };
    },
    mounted() {
        UrlUtils.HasBigUnitPrivilege(this, this.form.deptCode, (rst) => {
            this.hasBigUnitPrivilege = rst;
        });
    },
    filters: {
        status(status = '') {
            if (status == '0') {
                return '已下架';
            } else if (status == '1') {
                return '已上架';
            } else if (status == '2') {
                return '已禁售';
            } else {
                return status;
            }
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}

.el-form-item {
    width: auto;
}
</style>
